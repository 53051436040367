import { PiCopyrightLight } from "react-icons/pi";
import { NavLink } from "react-router-dom";
import logo from "../logo.png"

const Footer = () => {
    return(
        <footer>
            <div className="container color-dark-gray">
                <h2 className="fw-bold mb-3">Contáctanos</h2>
                <div className="lh-sm mb-4">
                    <h3>Ubicación:</h3>
                    <h4>27 Avenida 7-25 Colonia El Naranjo Bodega 11 Zona 4 de Mixco, Guatemala</h4>
                </div>
                <div className="lh-sm">
                    <h3>Número de Contacto:</h3>
                    <h4><NavLink to="tel:+50223562000">(502) 2356-2000</NavLink></h4>
                    <h4>Lunes a Viernes de 07.00 a 15.30 horas</h4>
                </div>
                <div className="container-footer-logo">
                    <figure className="container-footer-logo">
                        <img src={logo || "/placeholder.svg"} style={{ width: "346px", height: "153px" }} alt="logo" />
                        <span><br></br></span>
                    </figure>
                </div>
            </div>
            <div className="p-2 d-flex justify-content-center gap-2 footer-section-second footer-font">
                <span>
                    <span><PiCopyrightLight/></span>
                    <span> Copyright 2025</span>
                </span>
                <span>|</span>
                <span className="text-uppercase"><NavLink to="/">portinova</NavLink></span>
                <span>|</span>
                <span><NavLink to="https://www.yetiadvisers.com/" rel="noopener noreferrer nofollow" target="_blank">Desarrollado por YETI Advisers</NavLink></span>
            </div>
        </footer>
    );
}
export default Footer